<template>
    <footer id="footer" :class="{ 'mt-4': getFooterDetail !== 'version-simple' }">
        <div
            v-if="getFooterDetail !== 'version-simple' && getFooterDetail !== 'version-magazine'"
            class="bg-grey-light border-top"
        >
            <UIGridContainer>
                <UIGridRow>
                    <UIGridCol
                        v-if="getFooterDetail === 'version-1' || getFooterDetail === 'version-2'"
                        cols="12"
                        md="10"
                        class="payment-methods"
                    >
                        <nuxt-resource
                            v-for="(pm, index) in methods.slice(0, 6)"
                            :key="index"
                            lazy
                            :src="`images/payment/${pm.id}.svg`"
                            :alt="pm.description"
                            class="m-1 mr-md-3"
                            :width="30"
                            :height="30"
                        />

                        <button class="btn-link btn-payments" @click.stop.prevent="showPaymentMethodsModal()">
                            <div class="d-none d-sm-block">
                                {{ translate('paymentMethod.muchMore', { value: countRemainingMethods }) }}
                            </div>
                            <a href="#" class="underline">{{ translate('paymentMethod.showAll') }}</a>
                        </button>
                    </UIGridCol>

                    <UIGridCol
                        v-if="getFooterDetail === 'version-3' && $trustpilot.isVisible()"
                        cols="12"
                        class="trustpilot"
                    >
                        <TrustpilotComponent :horizontal="true" class="my-4" />
                    </UIGridCol>

                    <div v-if="getFooterDetail !== 'version-3'" class="line d-md-none" />

                    <UIGridCol v-if="getFooterDetail !== 'version-3'" cols="12" md="2" class="preferences">
                        <nuxt-link
                            class="home-link"
                            :to="$locale.path('index', 'en-US')"
                            lang="en-US"
                            aria-label="Link to the US homepage"
                        >
                            <UIIcon class="home-icon" :name="['fas', 'home']" alt="Home Icon" />
                        </nuxt-link>
                        <button
                            role="button"
                            class="btn-link btn-preferences"
                            @click.stop.prevent="showPreferencesModal()"
                        >
                            <div class="border-right">
                                <UIFlag class="icon" :code="$locale.regio" />
                            </div>
                            <div>{{ mapLanguage($locale.language) }}</div>
                            <div class="border-left">
                                {{ `${getSelectedCurrency}` }}
                                {{ getSelectedCurrencyCode ? `(${getSelectedCurrencyCode})` : '' }}
                            </div>
                        </button>
                    </UIGridCol>

                    <div class="line d-none d-md-block" />
                </UIGridRow>

                <UIGridRow>
                    <UIGridCol cols="12" :md="3" class="support accordion-wrapper">
                        <div id="accordion-support" class="btn btn-link">
                            <h2 class="accordion-title">
                                {{ translate('footer.rowTitles.support') }}
                            </h2>
                        </div>
                        <ul class="accordion-content list-nostyle">
                            <li>
                                <span class="link" @click="navigateToPage('support')">{{
                                    translate('support.contact.helpCenter')
                                }}</span>
                            </li>
                            <li>
                                <span class="link" @click="navigateToPage('service-contact', { history: 1 })">{{
                                    translate('support.contact.orderHistory')
                                }}</span>
                            </li>
                            <li>
                                <span class="link" @click="navigateToPage('legal-return-policy')">{{
                                    translate('footer.links.returnPolicy')
                                }}</span>
                            </li>
                            <li>
                                <span class="link" @click="navigateToPage('legal-complaint-policy')">{{
                                    translate('footer.links.complaint')
                                }}</span>
                            </li>
                            <li v-if="chatIsOnline">
                                <span class="link" @click="openLiveChat">{{
                                    translate('support.serviceLink.chat')
                                }}</span>
                            </li>
                            <li>
                                <span class="link" @click="navigateToPage('service-contact')">{{
                                    translate('support.contact.contactUs')
                                }}</span>
                            </li>
                        </ul>
                    </UIGridCol>

                    <UIGridCol cols="12" :md="3" class="legal accordion-wrapper">
                        <div id="accordion-legal" class="btn btn-link">
                            <h2 class="accordion-title">
                                {{ translate('footer.rowTitles.knowMore') }}
                            </h2>
                        </div>
                        <ul class="accordion-content list-nostyle">
                            <li>
                                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                                <span class="link" @click="navigateToPage('about')"
                                    >{{ translate('footer.rowTitles.about') }}
                                </span>
                            </li>
                            <li>
                                <span class="link" @click="navigateToPage('reward-info')">{{
                                    translate('footer.links.loyalty')
                                }}</span>
                            </li>
                            <li>
                                <span class="link" @click="showLegalModal()">{{
                                    translate('footer.rowTitles.legal')
                                }}</span>
                            </li>
                            <li v-if="$locale.language === 'de'">
                                <nuxt-link class="link" :to="$locale.path('legal-impressum')">Impressum</nuxt-link>
                            </li>
                        </ul>
                    </UIGridCol>

                    <UIGridCol
                        v-if="getFooterDetail === 'version-1' || getFooterDetail === 'version-3'"
                        cols="12"
                        :md="3"
                        class="social accordion-wrapper"
                    >
                        <div id="accordion-social" class="btn btn-link">
                            <h2 class="accordion-title">
                                {{ translate('footer.rowTitles.social') }}
                            </h2>
                        </div>
                        <ul class="accordion-content list-nostyle">
                            <li>
                                <a :href="`https://dundle.com/magazine/${$locale.language}`" class="link">
                                    Dundle Magazine
                                </a>
                            </li>
                            <li>
                                <UIIcon
                                    :name="['fab', 'facebook-square']"
                                    class="social-icon facebook-icon"
                                    alt="Facebook Icon"
                                />
                                <span class="link" @click="openNewWindow('facebook')">Facebook</span>
                            </li>
                            <li>
                                <UIIcon
                                    :name="['fab', 'instagram']"
                                    class="social-icon instagram-icon"
                                    alt="Instagram Icon"
                                />
                                <span class="link" @click="openNewWindow('instagram')"> Instagram </span>
                            </li>
                            <li>
                                <UIIcon :name="['fab', 'twitter-square']" class="social-icon" alt="Twitter Icon" />
                                <span class="link" @click="openNewWindow('twitter')"> Twitter </span>
                            </li>
                            <li>
                                <UIIcon :name="['fab', 'linkedin']" class="social-icon" alt="LinkedIn Icon" />
                                <span class="link" @click="openNewWindow('linkedin')"> LinkedIn </span>
                            </li>
                        </ul>
                    </UIGridCol>

                    <UIGridCol
                        v-if="getFooterDetail === 'version-1' || getFooterDetail === 'version-3'"
                        cols="12"
                        md="3"
                        class="app accordion-wrapper pt-0"
                    >
                        <div class="btn btn-link pl-md-0 pb-md-1">
                            <h2 class="accordion-title">
                                {{ translate('footer.rowTitles.app') }}
                            </h2>
                        </div>
                        <div class="app-links">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.dundle.app&referrer=utm_source%3Dwebsite%26utm_medium%3Dfooter%26utm_term%3Dapp_campaign%26anid%3Dadmob"
                                target="_blank"
                                class="mobile-app-link mr-2 pl-3 pl-md-0"
                                @click="trackGooglePlayDownload"
                                ><nuxt-resource
                                    :src="`images/occasion/app/google-play-badge-${
                                        isMarketingLanguage('app') ? $locale.language : 'en'
                                    }.svg`"
                                    alt="Google Play Store button"
                                    class="app-icon"
                                    lazy
                            /></a>
                            <a
                                href="https://apps.apple.com/app/dundle-prepaid-cards-egifts/id1605958640"
                                target="_blank"
                                class="mobile-app-link mr-2 pl-1 pl-md-0"
                                @click="trackAppStoreDownload"
                                ><nuxt-resource
                                    :src="`images/occasion/app/app-store-badge-${
                                        isMarketingLanguage('app') ? $locale.language : 'en'
                                    }.svg`"
                                    alt="Apple App Store button"
                                    class="app-icon"
                                    lazy
                            /></a>
                        </div>
                    </UIGridCol>

                    <UIGridCol
                        v-if="getFooterDetail === 'version-2'"
                        cols="12"
                        md="4"
                        class="trustpilot-footer align-self-center"
                    >
                        <TrustpilotComponent />
                    </UIGridCol>
                </UIGridRow>
            </UIGridContainer>
        </div>

        <MagazineFooter v-if="getFooterDetail === 'version-magazine'">
            <span class="hang-with-us">{{ translate('footer.rowTitles.social') }}</span>
        </MagazineFooter>

        <div class="bg-grey-dark">
            <UIGridContainer>
                <UIGridRow class="bg-grey-dark border-top">
                    <UIGridCol class="copyright">
                        <div class="copy">
                            {{ translate('footer.copy') }}
                        </div>
                        <div class="line my-3 d-md-none" />
                        <div class="powered-by">
                            <span>© {{ new Date().getFullYear() }} {{ $locale.domain }}</span>
                        </div>
                    </UIGridCol>
                </UIGridRow>
            </UIGridContainer>
        </div>

        <transition name="fade" duration="200" mode="out-in" tag="div">
            <UIToast v-if="liveChatOffline" @close="liveChatOffline = false">
                <template #icon>
                    <UIIcon :name="['fal', 'comment-slash']" />
                </template>
                <template #title>
                    {{ translate('support.liveChatOffline') }}
                </template>
                {{ translate('support.liveChatOfflineMsg') }}
                <UIButton class="w-100 mt-3" @click="goToContactPage">
                    {{ translate('support.contact.contactUs') }}
                </UIButton>
            </UIToast>
        </transition>

        <transition name="fade" duration="200" mode="out-in" tag="div">
            <UIToast v-if="liveChatLoading" @close="liveChatLoading = false">
                <UISpinner />
                {{ translate('support.liveChatLoading') }}
            </UIToast>
        </transition>
    </footer>
</template>

<script>
import { UIGridRow, UIGridCol, UIGridContainer, UIIcon, UIFlag, UISpinner, UIButton } from '@dundle/ui/components';
import { mapGetters, mapActions } from 'vuex';
import MagazineFooter from './MagazineFooter.vue';
import SettingsMixin from '~/mixins/SettingsMixin';
import TranslationMixin from '~/mixins/TranslationMixin';

import TrustpilotComponent from '~/components/layout/sidebar/TrustpilotComponent';
import MarketingMixin from '~/mixins/MarketingMixin';

export default {
    components: {
        UIGridRow,
        UIGridCol,
        UIGridContainer,
        UIIcon,
        UIFlag,
        TrustpilotComponent,
        MagazineFooter,
        UISpinner,
        UIButton,
        UIToast: () => import('@dundle/ui/components/UIToast'),
    },

    mixins: [SettingsMixin, TranslationMixin, MarketingMixin],

    data() {
        return {
            openAccordions: [],
            liveChatOffline: false,
            liveChatLoading: false,
        };
    },

    computed: {
        ...mapGetters({
            methods: 'data/getMethods', // payment methods with image
            chatIsOnline: 'support/isOnline', // when chat is online open live chat screen
            getFooterDetail: 'route/getFooter', // show 'simple' or 'detailed' footer
            getSelectedCurrency: 'currency/getSelectedCurrency', // show the selected currency and show with getSelectedCurrencyCode
            getSelectedCurrencyCode: 'currency/getSelectedCurrencySymbol', // show the selected currency code with getSelectedCurrency
        }),

        localeFacebook() {
            return 'https://www.facebook.com/DundleCom';
        },

        countRemainingMethods() {
            return this.methods.length;
        },
    },

    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
            fetchSupportStatus: 'support/fetchStatus',
        }),
        trackGooglePlayDownload() {
            this.$analytics.event('view_promotion', {
                creative_name: 'Footer Promo',
                creative_slot: 'footer_promo',
                promotion_id: 'app_promotion_default',
                promotion_name: 'App Promotion Standard',
            });
            this.$gtm?.push({ event: 'event_app_download_google_play_footer' });
        },
        trackAppStoreDownload() {
            this.$analytics.event('view_promotion', {
                creative_name: 'Footer Promo',
                creative_slot: 'footer_promo',
                promotion_id: 'app_promotion_default',
                promotion_name: 'App Promotion Standard',
            });
            this.$gtm?.push({ event: 'event_app_download_app_store_footer' });
        },
        showPaymentMethodsModal() {
            this.$analytics?.event('view_payment_methods_footer');

            this.showModal({ name: 'footer-payment-methods', isLargeModal: true });
        },
        showLegalModal() {
            this.$analytics?.event('pop_up_legal');
            this.showModal('legal');
        },

        async openLiveChat() {
            await this.fetchSupportStatus();

            if (this.chatIsOnline) {
                this.liveChatLoading = true;
                this.$cookies.set('cmnbhetpar', 'true', {
                    maxAge: 60 * 60 * 24 * 7 * 4,
                });

                await this.$livechat.open();
                this.liveChatLoading = false;
            } else {
                this.liveChatOffline = true;
                setTimeout(() => (this.liveChatOffline = false), 5000);
            }
        },

        // Links in footer shouldn't be indexed so navigate to them via functions
        navigateToPage(link, query) {
            return this.$router.push({ path: this.$locale.path(link), query });
        },
        openNewWindow(type) {
            let website = '';
            switch (type) {
                case 'facebook':
                    website = this.localeFacebook;
                    window.open(this.localeFacebook, '_blank');
                    break;
                case 'instagram':
                    website = 'https://www.instagram.com/dundle_com/';
                    window.open(website, '_blank');
                    break;
                case 'twitter':
                    website = 'https://twitter.com/Dundle_com/';
                    window.open(website, '_blank');
                    break;
                case 'linkedin':
                    website = 'https://www.linkedin.com/company/dundle/';
                    window.open(website, '_blank');
                    break;
                default:
                    break;
            }
            this.$analytics?.event('view_page_external', { page: type });
        },

        navigateToFacebook() {
            window.open('http://m.me/DundleCom');
        },

        navigateToTwitter() {
            window.open('https://twitter.com/messages/compose?recipient_id=1121771806011138049&ref_src=twsrc^tfw');
        },

        navigateToContact() {
            this.liveChatOffline = false;
            this.$router.push(this.$locale.path('service-contact'));
        },

        accordionIsOpen(accordion) {
            return this.openAccordions.includes(accordion);
        },

        goToContactPage() {
            this.$router.push(this.$locale.path('service-contact'));
        },

        showPreferencesModal() {
            this.$analytics.event('view_locale_switcher_footer');
            this.showModal('settings');
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-grey-light {
    background-color: $color-grey-1;
}

.bg-grey-dark {
    background-color: $color-grey-2;
}

.accordion-wrapper {
    // make button full width on mobile
    padding: 0;

    .btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-top: 1px solid $color-grey-2;
        border-bottom: 1px solid $color-grey-2;
        background-color: $color-grey-1;

        svg {
            font-size: 15px;

            @media only screen and (min-width: 768px) {
                display: none;
            }
        }

        &.btn-link {
            @media only screen and (min-width: 768px) {
                border: none;
                padding-bottom: 0;
                padding-left: 1rem;
                cursor: default;

                &:focus,
                &.active {
                    outline: none !important;
                }
            }
        }
    }
    .accordion-title {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1em;
    }
    .accordion-content {
        margin-bottom: 0;
        background-color: $color-bg;
        padding-left: 15px;
        padding-right: 15px;

        @media only screen and (min-width: 768px) {
            height: auto !important;
            display: block !important;
            background-color: transparent;
        }
    }

    ul.accordion-content li {
        line-height: 2em;
        // keeps the dropdown transition smooth
        &:first-child:before {
            content: '';
            display: block;
            height: 1em;
            @media only screen and (min-width: 768px) {
                height: 0.65em;
            }
        }
        &:last-child:after {
            content: '';
            display: block;
            height: 1em;
        }
    }
}

.btn-link {
    padding: 0.375rem 0.75rem;
}

ul.list-nostyle {
    li {
        list-style-type: none;
    }
}

.underline {
    text-decoration: underline;
}

.link {
    color: $color-grey-5;
    &:hover {
        color: $color-link-hover;
    }
}

.line {
    width: 100%;
    height: 1px;
    background-color: $color-grey-3;

    @media only screen and (min-width: 768px) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.btn-payments {
    line-height: 1em;
    color: $color-grey-5;
}
.payment-methods {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.preferences {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: center;

    @media only screen and (min-width: 768px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        justify-content: flex-end;
    }

    .home-link {
        svg {
            vertical-align: middle;
            font-size: 1.3rem;
        }
    }

    .btn-preferences {
        display: flex;
        white-space: nowrap;
        background-color: white;
        color: $color-grey-5;
        border: 1px solid $color-grey-3;
        padding: 0.45em 0em;
        margin-left: 1em;
        border-radius: $border-radius-default;

        > * {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        @media only screen and (min-width: 768px) {
            padding: 0.3em 0;
        }

        &:hover {
            border: 1px solid $color-grey-5;

            > * {
                border-color: $color-grey-5 !important;
            }
        }
    }
}

.social {
    ul.accordion-content li {
        @media only screen and (max-width: 767px) {
            line-height: 2.2em;
        }
    }
}
.social-icon {
    vertical-align: -0.3em;
    color: $color-link;
    width: 21px;
    height: 21px;
    margin-right: 0.2em;

    &.instagram-icon {
        width: 21px;
        height: 21px;
        color: $color-bg;
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
        padding: 0.1rem;
        border-radius: 5px;
    }
    &.facebook-icon {
        color: #4267b2;

        &:hover {
            color: darken(#4267b2, 10%);
        }
    }
}

.copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $color-grey-5;
    font-size: 0.85rem;
    font-weight: 400;

    @media only screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    .copy {
        @media only screen and (min-width: 768px) {
            max-width: 62%;
        }
    }

    .trustpilot-footer ::v-deep .trustpilot {
        margin-left: 0 !important;
    }

    .powered-by {
        @media only screen and (min-width: 768px) {
            text-align: right;
        }
        span + span {
            @media only screen and (min-width: 768px) {
                &:before {
                    content: '\A';
                    display: block;
                }
            }
        }
    }
}
.hang-with-us {
    margin-right: 1rem;
    @media only screen and (max-width: 768px) {
        margin-left: 1rem;
    }
}

.app {
    .app-links {
        padding: 1rem 0;

        background-color: white;
        @media screen and (min-width: 768px) {
            background-color: transparent;
            padding-top: 0;
        }
    }

    .app-icon {
        height: 36px;
        width: 122px;

        @media screen and (min-width: 768px) {
            height: 30px;
            width: 102px;
        }
        @media (min-width: 576px) and (max-width: 768px) {
            margin-top: 1rem;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/support.json"></i18n>
<i18n src="@dundle/locale/data/keywords.json"></i18n>
<i18n src="@dundle/locale/data/footer.json"></i18n>
<i18n src="@dundle/locale/data/loyalty.json"></i18n>
