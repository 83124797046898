import { render, staticRenderFns } from "./FooterComponent.vue?vue&type=template&id=11e19e1e&scoped=true"
import script from "./FooterComponent.vue?vue&type=script&lang=js"
export * from "./FooterComponent.vue?vue&type=script&lang=js"
import style0 from "./FooterComponent.vue?vue&type=style&index=0&id=11e19e1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e19e1e",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/support.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Ffooter%2FFooterComponent.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@dundle/locale/data/keywords.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Ffooter%2FFooterComponent.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@dundle/locale/data/footer.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Ffooter%2FFooterComponent.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@dundle/locale/data/loyalty.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Ffooter%2FFooterComponent.vue&external"
if (typeof block3 === 'function') block3(component)

export default component.exports